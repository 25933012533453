import HttpClient from './HttpClient';
// import store from '../store';

export default class Order {

    constructor (order = null) {

        this.or_ID = null;
        this.or_Number = null;
        this.or_CustomerID = null;
        this.or_Tax_Amt = null;
        this.or_Ship_Amt = null;
        this.or_Order_Coupon_Disc = null;
        this.or_CouponID = null;
        this.or_Total_Sale = null;
        this.or_First_4_cc = null;
        this.or_Last_4_cc = null;
        this.or_Purchase_Date = null;
        this.or_IPAddress = null;
        this.or_Country = null;
        this.or_Country_Code = null;
        this.offer_tag = null;
        this.or_Subscription_ID = null;
        this.or_last_modified_date = null;

        if (order && typeof order === 'object') {
            for (const k in order) {
                this[k] = order[k];
            }
        }
    }

    /**
     * Fetches the current user's orders
     */
    static async fetch ({ id = null, page = null }) {
        let payload = {
            path: '/laravel/rest/user/orders'
        };

        if (id && typeof id === 'number') {
            payload.url += `/${id}`;
        } else if (page && typeof page === 'number') {
            payload.query = { page };
        }

        const client = new HttpClient(payload);
        const resp = await client.get();
        
        if (resp?.status !== 200) {
            throw new Error(resp.status);
        }
        
        if (!resp.data.data) {
            throw new Error('Error fetching orders');
        }

        const { data, last_page, total } = resp.data;

        if (Array.isArray(data)) {
            return {
                data: data.filter(o => !!o.or_ID).map(o => new Order(o)),
                page,
                last_page,
                total
            };
        }

        return new Order(data);
    }
}